:root {
  /** Typography ---------------------------------------------------------------- */
  --base-font-family: 'Poppins';
  --base-font-size: 1rem;
  --text-font-weight: 400;
  --title-font-weight: 500;
  --heading-font-weight: 600;

  /** Colors -------------------------------------------------------------------- */
  --color-primary: #ff9520;
  --color-secondary: #2f3b4c;
  --color-secondary-dark: #273240;
  --color-neutral: #A9DDFC;
  --color-danger: #ec3f28;
  --color-success: #a3d62b;
  --color-neutral-alt: #a9defc70;
  --color-danger-alt: #ec3f285b;
  --color-success-alt: #a3d62b6c;
  --color-cta: #307ee1;
  --color-light: #f9f9fa;
  --color-white: #fff;
  --color-grey-light: #f2f2f2;
  --color-grey1: #3e3e3e;
  --color-grey2: #838383;
  --color-grey3: #c4c4c4;
  --color-grey4: #fafbfc;
  --color-grey5: #f6f8f9;
  --color-grey6: #646f79;
  --color-red-dark: #e18e96;
  --color-red-light: #fef1f2;
  --color-text-secondary: #33475b;
  --color-dark: #3E3E3E;

  /** Layout -------------------------------------------------------------------- */
  --navbar-height: 84px;
  --navbar-mobile-header-height: 84px;
  --footer-height: 360px;
  --page-min-height: calc(100vh - var(--navbar-height) - var(--footer-height));
  --content-block-max-width: 1200px;
  --content-block-narrow-max-width: 800px;
  --content-block-narrower-max-width: 600px;
  @media (min-width: 576px) and (max-width: 1023px) {
    --content-block-max-width: 100%;
    --content-block-narrow-max-width: 100%;
    --content-block-narrower-max-width: 100%;
  }
  @media (min-width: 1024px) and (max-width: 1366px) {
    --content-block-max-width: 1000px;
    --content-block-narrow-max-width: 870px;
    --content-block-narrower-max-width: 725px;
  }
  @media (min-width: 1367px) and (max-width: 1600px) {
    --content-block-max-width: 1200px;
    --content-block-narrow-max-width: 940px;
    --content-block-narrower-max-width: 725px;
  }
  @media (min-width: 1601px) and (max-width: 1920px) {
    --content-block-max-width: 1400px;
    --content-block-narrow-max-width: 1200px;
    --content-block-narrower-max-width: 1000px;
  }
  @media (min-width: 1921px) {
    --content-block-max-width: 1600px;
    --content-block-narrow-max-width: 1200px;
    --content-block-narrower-max-width: 1000px;
  }
  /** Shadow ------------------------------------------------------------------- */
  --box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
  /** Border ------------------------------------------------------------------- */
  --border-radius-small: 5px;
  --border-radius-regular: 10px;
  --border-radius-large: 20px;
  /** Spacing ------------------------------------------------------------------- */
  --padding-extra-small: 5px;
  --padding-small: 10px;
  --padding-regular: 30px;
  --padding-large: 80px;
  --padding-extra-large: 160px;
  /** Dark Mode Colors ---------------------------------------------------------- */
  @media (prefers-color-scheme: dark) {
    html {
      color-scheme: dark;
    }
  }
}